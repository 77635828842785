// @flow
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";

import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";
// import "./annotation.css";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import RightArrow from "../../../root/img/modal/right-arrow.svg";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true
};

type Props = {
  file: string,
  onError: () => void
};

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const DocPreview = ({ file, wrapperDivSize = 0, calculateHeight = false, onError, availableHeight = 0 }: Props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const nav = useRef();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const renderLoading = () => {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  };

  return (
    <Container>
      <ExpandableWrapper>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          loading={renderLoading}
          externalLinkTarget="_blank"
          onLoadError={onError}
        >
          <Page
            renderTextLayer={false}
            className="page-style"
            pageNumber={pageNumber}
            key={`page_${pageNumber}`}
            width={wrapperDivSize}
            renderAnnotationLayer={false}
          />
        </Document>
      </ExpandableWrapper>
      {(numPages && numPages > 1) ? (
        <Navigation ref={nav}>
          <Button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
            className="left-button"
          >
            <RightArrow width="24px" fill="#444"/>
          </Button>
          <NavigationLabel>
            {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
          </NavigationLabel>
          <Button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
            className="right-button"
          >
            <RightArrow width="24px" fill="#444"/>
          </Button>
        </Navigation>
      ) : (
        <Navigation ref={nav}>
          <NavigationLabel>
            {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
          </NavigationLabel>
        </Navigation>
      )}
    </Container>
  );
};

export default DocPreview;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  /* background-color: rgb(82, 86, 89); */
  background-color: #EBECED;
  user-select: none;

  input,
  button {
    font: inherit;
  }

  .page-style {
    background-color: #fff;
    max-width: calc(~"100% - 2em");
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
    canvas {
      max-width: 100%;
      height: auto !important;
    }
  }

  &__container {
    &__load {
      margin-top: 1em;
      color: white;
    }

    .react-pdf {
      &__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__Page {
        canvas {
          max-width: 100%;
          height: auto !important;
        }
      }

      &__message {
        padding: 20px;
        color: white;
      }
    }
  }
`;

const ExpandableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  height: 100%;
`;


const NavigationLabel = styled.p`
  text-align: center;
  color: #444;
  font-size: 14px !important;
  text-wrap: nowrap;
`;

const Navigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 20px;
  box-sizing: border-box;
`;

const Button = styled.button`
  border-radius: 24px;
  display: inline-block;
  width: 50%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 14px !important;
  font-weight: bold !important;
  color: #444;
  background-color: #fff;
  border: 1px solid #E4E4E4;
  max-width: 240px;

  span {
    flex-grow: 1;
  }

  &:disabled {
    background-color: #f2f2f3;
    color: #ADADAD;

    svg {
      fill: #ADADAD;
    }
  }

  &.left-button {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const LoaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #EBECED;
  top: 0;
  left: 0;
  z-index: 1001;
`;